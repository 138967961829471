// layout mode for MaskRows
// custom layout mode that positions items centrally
// console.log('maskRowsDefinition');
( function( window )
{



function maskRowsDefinition( LayoutMode ) {
console.log('maskRowsDefinition'+LayoutMode);
var MaskRows = LayoutMode.create('maskRows');

MaskRows.prototype._resetLayout = function() {
  this.x = 0;
  this.width = 0;
  this.y = 0;
  this.maxY = 0;
  this._getMeasurement( 'gutter', 'outerWidth' );
  this.rowPositions = [];
};

MaskRows.prototype._getItemLayoutPosition = function( item ) {

  item.getSize();

  // get the width of the item and the container
  var itemWidth = item.size.outerWidth + this.gutter;
  var containerWidth = this.isotope.size.innerWidth + this.gutter;
  console.log('itemWidth:'+itemWidth);
  // if this element cannot fit in the current row
  if ( this.width !== 0 && itemWidth + this.width > containerWidth )
  {

    this.rowPositions = [];
    //position new items in the centre
    this.width = itemWidth;
    this.x = containerWidth/2 - this.width/2;
    this.y = this.maxY;
    console.log('new row');
    console.log('this.x:'+this.x);
  } else
  {
    this.width += itemWidth;
    // loop through row positioning items;
    var startPoint = containerWidth/2 - this.width/2;

    var i,
        arr = this.rowPositions;
        // console.log('('+containerWidth+"-"+this.x+') / 2 = '+gap);
    //console.log('position row arr.length:'+arr.length)
    for (i=0; i < arr.length; i++)
    {
      // console.log(arr[i]);
      // console.log(arr[i].x);
      var pos = arr[i];

      pos.x = startPoint;
      startPoint += pos.item.size.outerWidth + this.gutter;
      // console.log(i+":"+pos.item.size.outerWidth)

      //$(arr[i]).css('left', '+='+gap);
    };
    // console.log("startPoint:"+startPoint);
    // console.log('----------------')
    this.x = startPoint;
  }

  var position = {
    x: this.x,
    y: this.y
  };

  this.maxY = Math.max( this.maxY, this.y + item.size.outerHeight );
  this.x += startPoint;
  console.log('this.x:'+this.x);
  // console.log(position);
  this.rowPositions.push(position);
  return position;
};

MaskRows.prototype._getContainerSize = function() {
  return { height: this.maxY };
};

return MaskRows;

}

if ( typeof define === 'function' && define.amd ) {
  // AMD
  define( 'isotope/js/layout-modes/mask-rows',[
      '../layout-mode'
    ],
    fitRowsDefinition );
} else if ( typeof exports === 'object' ) {
  // CommonJS
  module.exports = maskRowsDefinition(
    require('../layout-mode')
  );
} else {
  // browser global
  maskRowsDefinition(
    window.Isotope.LayoutMode
  );
}

})( window );